import { Button, Col, Input, Row, Space } from "antd";
import { ChangeEvent, useState } from "react";
import {
  useNavigate,
  NavigateOptions,
  useSearchParams,
} from "react-router-dom";
export interface IHeaderProps {}

export function HeaderView(props: IHeaderProps) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({ query: "" });
  const { query } = data;
  const navigate = useNavigate();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log(event);
    setData((prevState) => ({
      query: event.target.value,
    }));
  };
  const onClick = (event: any) => {
    // console.log("Search query: ", query);
    // setSearchParams({ query: query });

    const options: NavigateOptions = {
      state: { query: query },
    };

    navigate("/list?query=" + query);
  };

  return (
    <>
      <Row style={{ alignItems: "center" }} justify="center" gutter={5}>
        <Col span={2}>
          <img
            src="/images/logo-mob-big.png"
            alt="Logo"
            style={{
              margin: "0 auto",
              padding: "12px 0",
              maxHeight: "100px",
            }}
          />
        </Col>
        <Col span={16}>
          <h1>Реестр юридических лиц, филиалов (представительств)</h1>
        </Col>
        <Col span={6}>
          <Space style={{ padding: 3 }}>
            {"ИНН"}
            <Input value={query} onChange={onChange} />
            <Button type="primary" onClick={onClick}>
              Поиск
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
}
