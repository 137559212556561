import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../api";

import {
  defaultLayoutPlugin,
  DefaultLayoutPluginProps,
} from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer, ProgressBar } from "@react-pdf-viewer/core";

export interface IRecordViewProps {
  // id: string | undefined;
}

const defaultLayoutPluginProps: DefaultLayoutPluginProps = {};

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const RecordView: React.FC<IRecordViewProps> = () => {
  const { id } = useParams();
  const [file, setFile] = useState();
  console.log(new Date());

  // useEffect(() => {
  //   API.get(`/api/v1/register/${id}/record`).then((response) => {
  //     setFile(response.data);
  //   });
  // },[]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin(
    defaultLayoutPluginProps
  );

  const onDocumentLoad = (props: any) => {
    console.log("Document loaded: %o %d", props.file.name, props.doc.numPages);
  };

  if (id) {
    return (
      <Worker workerUrl='/pdf.worker.min.js'>
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            height: "750px",
          }}
        >
          <Viewer
            fileUrl={`${apiUrl}/api/v1/register/${id}/record`}
            onDocumentLoad={onDocumentLoad}
            plugins={[defaultLayoutPluginInstance]}
            renderLoader={(percentages: number) => (
              <div style={{ width: "240px" }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
          />
        </div>
      </Worker>
    );
  } else {
    return <></>;
  }
};

export default RecordView;
