import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, Row, Col } from "antd";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link, useLocation, useParams } from "react-router-dom";
import API from "../../api";

export interface IRecordViewProps {
  query: string;
}
const defaultFormData = {
  query: "",
};

interface LocalizedMessage {
  ky?: string;
  ru?: string;
  en?: string;
}

interface DataType {
  id: string;
  registerCode: string;
  name: LocalizedMessage;
  tin: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "ИНН",
    key: "tin",
    dataIndex: "tin",
  },
  {
    title: "Регистрационный номер",
    dataIndex: "registerCode",
    key: "registerCode",
  },
  {
    title: "Наименование на государственном языке",
    dataIndex: "name",
    key: "nameky",
    render: (_, record) => <Space size="middle">{record.name.ky}</Space>,
  },
  {
    title: "Наименование на официальном языке",
    dataIndex: "name.ru",
    key: "nameru",
    render: (_, record) => <Space size="middle">{record.name.ru}</Space>,
  },
  {
    title: "Действие",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Link to={`/view/${record.tin}`}>Выписка</Link>
      </Space>
    ),
  },
];

export default function RecordSearch(props: IRecordViewProps) {
  const [data, setData] = useState<DataType[]>();
  const search = useLocation().search;
  console.log(search);
  const query = new URLSearchParams(search).get("query");
  // console.log(params);
  useEffect(() => {
    API.get("/api/v1/register", { params: { query: query } }).then(
      (response) => {
        console.log(response.data);
        const items: DataType[] = [response.data];
        setData(items);
      }
    );
  }, [query]);
  // const [formData, setFormData] = useState(defaultFormData);
  // const { query } = formData;

  // const onChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   console.log(event);
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     query: event.target.value,
  //   }));
  // };
  // const onClick = (event: any) => {
  // console.log(event);
  // };

  return (
    <>
      <Table columns={columns} dataSource={data} />
    </>
  );
}
