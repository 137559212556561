import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/reset.css";
import "./App.css";
import RecordView from "./components/pages/RecordView";
import RecordSearch from "./components/pages/RecordSearch";
import { HeaderView } from "./components/HeaderView";
const { Header, Footer, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "left",
  color: "#fff",
  height: 100,
  paddingInline: 50,
  lineHeight: "64px",
  // backgroundColor: "#7dbcea",
};

const contentStyle: React.CSSProperties = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#000000",
  // backgroundColor: "#108ee9",
};

const siderStyle: React.CSSProperties = {
  textAlign: "center",
  lineHeight: "120px",
  // color: "#fff",
  // backgroundColor: "#3ba0e9",
};

const footerStyle: React.CSSProperties = {
  textAlign: "center",
  // color: "#fff",
  // backgroundColor: "#7dbcea",
};

function App() {
  return (
    <Router>
      <Layout>
        <Header style={headerStyle}>
          <HeaderView />
        </Header>

        <Content>
          <Routes>
            <Route path="/list" element={<RecordSearch query="" />} />
            <Route path="/view/:id" element={<RecordView />} />
          </Routes>
        </Content>

        <Footer />
      </Layout>
    </Router>
  );
}

export default App;
